import _AcademicCapIcon from "./AcademicCapIcon.js";
import _AdjustmentsHorizontalIcon from "./AdjustmentsHorizontalIcon.js";
import _AdjustmentsVerticalIcon from "./AdjustmentsVerticalIcon.js";
import _ArchiveBoxArrowDownIcon from "./ArchiveBoxArrowDownIcon.js";
import _ArchiveBoxXMarkIcon from "./ArchiveBoxXMarkIcon.js";
import _ArchiveBoxIcon from "./ArchiveBoxIcon.js";
import _ArrowDownCircleIcon from "./ArrowDownCircleIcon.js";
import _ArrowDownLeftIcon from "./ArrowDownLeftIcon.js";
import _ArrowDownOnSquareStackIcon from "./ArrowDownOnSquareStackIcon.js";
import _ArrowDownOnSquareIcon from "./ArrowDownOnSquareIcon.js";
import _ArrowDownRightIcon from "./ArrowDownRightIcon.js";
import _ArrowDownTrayIcon from "./ArrowDownTrayIcon.js";
import _ArrowDownIcon from "./ArrowDownIcon.js";
import _ArrowLeftCircleIcon from "./ArrowLeftCircleIcon.js";
import _ArrowLeftEndOnRectangleIcon from "./ArrowLeftEndOnRectangleIcon.js";
import _ArrowLeftOnRectangleIcon from "./ArrowLeftOnRectangleIcon.js";
import _ArrowLeftStartOnRectangleIcon from "./ArrowLeftStartOnRectangleIcon.js";
import _ArrowLeftIcon from "./ArrowLeftIcon.js";
import _ArrowLongDownIcon from "./ArrowLongDownIcon.js";
import _ArrowLongLeftIcon from "./ArrowLongLeftIcon.js";
import _ArrowLongRightIcon from "./ArrowLongRightIcon.js";
import _ArrowLongUpIcon from "./ArrowLongUpIcon.js";
import _ArrowPathRoundedSquareIcon from "./ArrowPathRoundedSquareIcon.js";
import _ArrowPathIcon from "./ArrowPathIcon.js";
import _ArrowRightCircleIcon from "./ArrowRightCircleIcon.js";
import _ArrowRightEndOnRectangleIcon from "./ArrowRightEndOnRectangleIcon.js";
import _ArrowRightOnRectangleIcon from "./ArrowRightOnRectangleIcon.js";
import _ArrowRightStartOnRectangleIcon from "./ArrowRightStartOnRectangleIcon.js";
import _ArrowRightIcon from "./ArrowRightIcon.js";
import _ArrowSmallDownIcon from "./ArrowSmallDownIcon.js";
import _ArrowSmallLeftIcon from "./ArrowSmallLeftIcon.js";
import _ArrowSmallRightIcon from "./ArrowSmallRightIcon.js";
import _ArrowSmallUpIcon from "./ArrowSmallUpIcon.js";
import _ArrowTopRightOnSquareIcon from "./ArrowTopRightOnSquareIcon.js";
import _ArrowTrendingDownIcon from "./ArrowTrendingDownIcon.js";
import _ArrowTrendingUpIcon from "./ArrowTrendingUpIcon.js";
import _ArrowTurnDownLeftIcon from "./ArrowTurnDownLeftIcon.js";
import _ArrowTurnDownRightIcon from "./ArrowTurnDownRightIcon.js";
import _ArrowTurnLeftDownIcon from "./ArrowTurnLeftDownIcon.js";
import _ArrowTurnLeftUpIcon from "./ArrowTurnLeftUpIcon.js";
import _ArrowTurnRightDownIcon from "./ArrowTurnRightDownIcon.js";
import _ArrowTurnRightUpIcon from "./ArrowTurnRightUpIcon.js";
import _ArrowTurnUpLeftIcon from "./ArrowTurnUpLeftIcon.js";
import _ArrowTurnUpRightIcon from "./ArrowTurnUpRightIcon.js";
import _ArrowUpCircleIcon from "./ArrowUpCircleIcon.js";
import _ArrowUpLeftIcon from "./ArrowUpLeftIcon.js";
import _ArrowUpOnSquareStackIcon from "./ArrowUpOnSquareStackIcon.js";
import _ArrowUpOnSquareIcon from "./ArrowUpOnSquareIcon.js";
import _ArrowUpRightIcon from "./ArrowUpRightIcon.js";
import _ArrowUpTrayIcon from "./ArrowUpTrayIcon.js";
import _ArrowUpIcon from "./ArrowUpIcon.js";
import _ArrowUturnDownIcon from "./ArrowUturnDownIcon.js";
import _ArrowUturnLeftIcon from "./ArrowUturnLeftIcon.js";
import _ArrowUturnRightIcon from "./ArrowUturnRightIcon.js";
import _ArrowUturnUpIcon from "./ArrowUturnUpIcon.js";
import _ArrowsPointingInIcon from "./ArrowsPointingInIcon.js";
import _ArrowsPointingOutIcon from "./ArrowsPointingOutIcon.js";
import _ArrowsRightLeftIcon from "./ArrowsRightLeftIcon.js";
import _ArrowsUpDownIcon from "./ArrowsUpDownIcon.js";
import _AtSymbolIcon from "./AtSymbolIcon.js";
import _BackspaceIcon from "./BackspaceIcon.js";
import _BackwardIcon from "./BackwardIcon.js";
import _BanknotesIcon from "./BanknotesIcon.js";
import _Bars2Icon from "./Bars2Icon.js";
import _Bars3BottomLeftIcon from "./Bars3BottomLeftIcon.js";
import _Bars3BottomRightIcon from "./Bars3BottomRightIcon.js";
import _Bars3CenterLeftIcon from "./Bars3CenterLeftIcon.js";
import _Bars3Icon from "./Bars3Icon.js";
import _Bars4Icon from "./Bars4Icon.js";
import _BarsArrowDownIcon from "./BarsArrowDownIcon.js";
import _BarsArrowUpIcon from "./BarsArrowUpIcon.js";
import _Battery0Icon from "./Battery0Icon.js";
import _Battery100Icon from "./Battery100Icon.js";
import _Battery50Icon from "./Battery50Icon.js";
import _BeakerIcon from "./BeakerIcon.js";
import _BellAlertIcon from "./BellAlertIcon.js";
import _BellSlashIcon from "./BellSlashIcon.js";
import _BellSnoozeIcon from "./BellSnoozeIcon.js";
import _BellIcon from "./BellIcon.js";
import _BoldIcon from "./BoldIcon.js";
import _BoltSlashIcon from "./BoltSlashIcon.js";
import _BoltIcon from "./BoltIcon.js";
import _BookOpenIcon from "./BookOpenIcon.js";
import _BookmarkSlashIcon from "./BookmarkSlashIcon.js";
import _BookmarkSquareIcon from "./BookmarkSquareIcon.js";
import _BookmarkIcon from "./BookmarkIcon.js";
import _BriefcaseIcon from "./BriefcaseIcon.js";
import _BugAntIcon from "./BugAntIcon.js";
import _BuildingLibraryIcon from "./BuildingLibraryIcon.js";
import _BuildingOffice2Icon from "./BuildingOffice2Icon.js";
import _BuildingOfficeIcon from "./BuildingOfficeIcon.js";
import _BuildingStorefrontIcon from "./BuildingStorefrontIcon.js";
import _CakeIcon from "./CakeIcon.js";
import _CalculatorIcon from "./CalculatorIcon.js";
import _CalendarDateRangeIcon from "./CalendarDateRangeIcon.js";
import _CalendarDaysIcon from "./CalendarDaysIcon.js";
import _CalendarIcon from "./CalendarIcon.js";
import _CameraIcon from "./CameraIcon.js";
import _ChartBarSquareIcon from "./ChartBarSquareIcon.js";
import _ChartBarIcon from "./ChartBarIcon.js";
import _ChartPieIcon from "./ChartPieIcon.js";
import _ChatBubbleBottomCenterTextIcon from "./ChatBubbleBottomCenterTextIcon.js";
import _ChatBubbleBottomCenterIcon from "./ChatBubbleBottomCenterIcon.js";
import _ChatBubbleLeftEllipsisIcon from "./ChatBubbleLeftEllipsisIcon.js";
import _ChatBubbleLeftRightIcon from "./ChatBubbleLeftRightIcon.js";
import _ChatBubbleLeftIcon from "./ChatBubbleLeftIcon.js";
import _ChatBubbleOvalLeftEllipsisIcon from "./ChatBubbleOvalLeftEllipsisIcon.js";
import _ChatBubbleOvalLeftIcon from "./ChatBubbleOvalLeftIcon.js";
import _CheckBadgeIcon from "./CheckBadgeIcon.js";
import _CheckCircleIcon from "./CheckCircleIcon.js";
import _CheckIcon from "./CheckIcon.js";
import _ChevronDoubleDownIcon from "./ChevronDoubleDownIcon.js";
import _ChevronDoubleLeftIcon from "./ChevronDoubleLeftIcon.js";
import _ChevronDoubleRightIcon from "./ChevronDoubleRightIcon.js";
import _ChevronDoubleUpIcon from "./ChevronDoubleUpIcon.js";
import _ChevronDownIcon from "./ChevronDownIcon.js";
import _ChevronLeftIcon from "./ChevronLeftIcon.js";
import _ChevronRightIcon from "./ChevronRightIcon.js";
import _ChevronUpDownIcon from "./ChevronUpDownIcon.js";
import _ChevronUpIcon from "./ChevronUpIcon.js";
import _CircleStackIcon from "./CircleStackIcon.js";
import _ClipboardDocumentCheckIcon from "./ClipboardDocumentCheckIcon.js";
import _ClipboardDocumentListIcon from "./ClipboardDocumentListIcon.js";
import _ClipboardDocumentIcon from "./ClipboardDocumentIcon.js";
import _ClipboardIcon from "./ClipboardIcon.js";
import _ClockIcon from "./ClockIcon.js";
import _CloudArrowDownIcon from "./CloudArrowDownIcon.js";
import _CloudArrowUpIcon from "./CloudArrowUpIcon.js";
import _CloudIcon from "./CloudIcon.js";
import _CodeBracketSquareIcon from "./CodeBracketSquareIcon.js";
import _CodeBracketIcon from "./CodeBracketIcon.js";
import _Cog6ToothIcon from "./Cog6ToothIcon.js";
import _Cog8ToothIcon from "./Cog8ToothIcon.js";
import _CogIcon from "./CogIcon.js";
import _CommandLineIcon from "./CommandLineIcon.js";
import _ComputerDesktopIcon from "./ComputerDesktopIcon.js";
import _CpuChipIcon from "./CpuChipIcon.js";
import _CreditCardIcon from "./CreditCardIcon.js";
import _CubeTransparentIcon from "./CubeTransparentIcon.js";
import _CubeIcon from "./CubeIcon.js";
import _CurrencyBangladeshiIcon from "./CurrencyBangladeshiIcon.js";
import _CurrencyDollarIcon from "./CurrencyDollarIcon.js";
import _CurrencyEuroIcon from "./CurrencyEuroIcon.js";
import _CurrencyPoundIcon from "./CurrencyPoundIcon.js";
import _CurrencyRupeeIcon from "./CurrencyRupeeIcon.js";
import _CurrencyYenIcon from "./CurrencyYenIcon.js";
import _CursorArrowRaysIcon from "./CursorArrowRaysIcon.js";
import _CursorArrowRippleIcon from "./CursorArrowRippleIcon.js";
import _DevicePhoneMobileIcon from "./DevicePhoneMobileIcon.js";
import _DeviceTabletIcon from "./DeviceTabletIcon.js";
import _DivideIcon from "./DivideIcon.js";
import _DocumentArrowDownIcon from "./DocumentArrowDownIcon.js";
import _DocumentArrowUpIcon from "./DocumentArrowUpIcon.js";
import _DocumentChartBarIcon from "./DocumentChartBarIcon.js";
import _DocumentCheckIcon from "./DocumentCheckIcon.js";
import _DocumentCurrencyBangladeshiIcon from "./DocumentCurrencyBangladeshiIcon.js";
import _DocumentCurrencyDollarIcon from "./DocumentCurrencyDollarIcon.js";
import _DocumentCurrencyEuroIcon from "./DocumentCurrencyEuroIcon.js";
import _DocumentCurrencyPoundIcon from "./DocumentCurrencyPoundIcon.js";
import _DocumentCurrencyRupeeIcon from "./DocumentCurrencyRupeeIcon.js";
import _DocumentCurrencyYenIcon from "./DocumentCurrencyYenIcon.js";
import _DocumentDuplicateIcon from "./DocumentDuplicateIcon.js";
import _DocumentMagnifyingGlassIcon from "./DocumentMagnifyingGlassIcon.js";
import _DocumentMinusIcon from "./DocumentMinusIcon.js";
import _DocumentPlusIcon from "./DocumentPlusIcon.js";
import _DocumentTextIcon from "./DocumentTextIcon.js";
import _DocumentIcon from "./DocumentIcon.js";
import _EllipsisHorizontalCircleIcon from "./EllipsisHorizontalCircleIcon.js";
import _EllipsisHorizontalIcon from "./EllipsisHorizontalIcon.js";
import _EllipsisVerticalIcon from "./EllipsisVerticalIcon.js";
import _EnvelopeOpenIcon from "./EnvelopeOpenIcon.js";
import _EnvelopeIcon from "./EnvelopeIcon.js";
import _EqualsIcon from "./EqualsIcon.js";
import _ExclamationCircleIcon from "./ExclamationCircleIcon.js";
import _ExclamationTriangleIcon from "./ExclamationTriangleIcon.js";
import _EyeDropperIcon from "./EyeDropperIcon.js";
import _EyeSlashIcon from "./EyeSlashIcon.js";
import _EyeIcon from "./EyeIcon.js";
import _FaceFrownIcon from "./FaceFrownIcon.js";
import _FaceSmileIcon from "./FaceSmileIcon.js";
import _FilmIcon from "./FilmIcon.js";
import _FingerPrintIcon from "./FingerPrintIcon.js";
import _FireIcon from "./FireIcon.js";
import _FlagIcon from "./FlagIcon.js";
import _FolderArrowDownIcon from "./FolderArrowDownIcon.js";
import _FolderMinusIcon from "./FolderMinusIcon.js";
import _FolderOpenIcon from "./FolderOpenIcon.js";
import _FolderPlusIcon from "./FolderPlusIcon.js";
import _FolderIcon from "./FolderIcon.js";
import _ForwardIcon from "./ForwardIcon.js";
import _FunnelIcon from "./FunnelIcon.js";
import _GifIcon from "./GifIcon.js";
import _GiftTopIcon from "./GiftTopIcon.js";
import _GiftIcon from "./GiftIcon.js";
import _GlobeAltIcon from "./GlobeAltIcon.js";
import _GlobeAmericasIcon from "./GlobeAmericasIcon.js";
import _GlobeAsiaAustraliaIcon from "./GlobeAsiaAustraliaIcon.js";
import _GlobeEuropeAfricaIcon from "./GlobeEuropeAfricaIcon.js";
import _H1Icon from "./H1Icon.js";
import _H2Icon from "./H2Icon.js";
import _H3Icon from "./H3Icon.js";
import _HandRaisedIcon from "./HandRaisedIcon.js";
import _HandThumbDownIcon from "./HandThumbDownIcon.js";
import _HandThumbUpIcon from "./HandThumbUpIcon.js";
import _HashtagIcon from "./HashtagIcon.js";
import _HeartIcon from "./HeartIcon.js";
import _HomeModernIcon from "./HomeModernIcon.js";
import _HomeIcon from "./HomeIcon.js";
import _IdentificationIcon from "./IdentificationIcon.js";
import _InboxArrowDownIcon from "./InboxArrowDownIcon.js";
import _InboxStackIcon from "./InboxStackIcon.js";
import _InboxIcon from "./InboxIcon.js";
import _InformationCircleIcon from "./InformationCircleIcon.js";
import _ItalicIcon from "./ItalicIcon.js";
import _KeyIcon from "./KeyIcon.js";
import _LanguageIcon from "./LanguageIcon.js";
import _LifebuoyIcon from "./LifebuoyIcon.js";
import _LightBulbIcon from "./LightBulbIcon.js";
import _LinkSlashIcon from "./LinkSlashIcon.js";
import _LinkIcon from "./LinkIcon.js";
import _ListBulletIcon from "./ListBulletIcon.js";
import _LockClosedIcon from "./LockClosedIcon.js";
import _LockOpenIcon from "./LockOpenIcon.js";
import _MagnifyingGlassCircleIcon from "./MagnifyingGlassCircleIcon.js";
import _MagnifyingGlassMinusIcon from "./MagnifyingGlassMinusIcon.js";
import _MagnifyingGlassPlusIcon from "./MagnifyingGlassPlusIcon.js";
import _MagnifyingGlassIcon from "./MagnifyingGlassIcon.js";
import _MapPinIcon from "./MapPinIcon.js";
import _MapIcon from "./MapIcon.js";
import _MegaphoneIcon from "./MegaphoneIcon.js";
import _MicrophoneIcon from "./MicrophoneIcon.js";
import _MinusCircleIcon from "./MinusCircleIcon.js";
import _MinusSmallIcon from "./MinusSmallIcon.js";
import _MinusIcon from "./MinusIcon.js";
import _MoonIcon from "./MoonIcon.js";
import _MusicalNoteIcon from "./MusicalNoteIcon.js";
import _NewspaperIcon from "./NewspaperIcon.js";
import _NoSymbolIcon from "./NoSymbolIcon.js";
import _NumberedListIcon from "./NumberedListIcon.js";
import _PaintBrushIcon from "./PaintBrushIcon.js";
import _PaperAirplaneIcon from "./PaperAirplaneIcon.js";
import _PaperClipIcon from "./PaperClipIcon.js";
import _PauseCircleIcon from "./PauseCircleIcon.js";
import _PauseIcon from "./PauseIcon.js";
import _PencilSquareIcon from "./PencilSquareIcon.js";
import _PencilIcon from "./PencilIcon.js";
import _PercentBadgeIcon from "./PercentBadgeIcon.js";
import _PhoneArrowDownLeftIcon from "./PhoneArrowDownLeftIcon.js";
import _PhoneArrowUpRightIcon from "./PhoneArrowUpRightIcon.js";
import _PhoneXMarkIcon from "./PhoneXMarkIcon.js";
import _PhoneIcon from "./PhoneIcon.js";
import _PhotoIcon from "./PhotoIcon.js";
import _PlayCircleIcon from "./PlayCircleIcon.js";
import _PlayPauseIcon from "./PlayPauseIcon.js";
import _PlayIcon from "./PlayIcon.js";
import _PlusCircleIcon from "./PlusCircleIcon.js";
import _PlusSmallIcon from "./PlusSmallIcon.js";
import _PlusIcon from "./PlusIcon.js";
import _PowerIcon from "./PowerIcon.js";
import _PresentationChartBarIcon from "./PresentationChartBarIcon.js";
import _PresentationChartLineIcon from "./PresentationChartLineIcon.js";
import _PrinterIcon from "./PrinterIcon.js";
import _PuzzlePieceIcon from "./PuzzlePieceIcon.js";
import _QrCodeIcon from "./QrCodeIcon.js";
import _QuestionMarkCircleIcon from "./QuestionMarkCircleIcon.js";
import _QueueListIcon from "./QueueListIcon.js";
import _RadioIcon from "./RadioIcon.js";
import _ReceiptPercentIcon from "./ReceiptPercentIcon.js";
import _ReceiptRefundIcon from "./ReceiptRefundIcon.js";
import _RectangleGroupIcon from "./RectangleGroupIcon.js";
import _RectangleStackIcon from "./RectangleStackIcon.js";
import _RocketLaunchIcon from "./RocketLaunchIcon.js";
import _RssIcon from "./RssIcon.js";
import _ScaleIcon from "./ScaleIcon.js";
import _ScissorsIcon from "./ScissorsIcon.js";
import _ServerStackIcon from "./ServerStackIcon.js";
import _ServerIcon from "./ServerIcon.js";
import _ShareIcon from "./ShareIcon.js";
import _ShieldCheckIcon from "./ShieldCheckIcon.js";
import _ShieldExclamationIcon from "./ShieldExclamationIcon.js";
import _ShoppingBagIcon from "./ShoppingBagIcon.js";
import _ShoppingCartIcon from "./ShoppingCartIcon.js";
import _SignalSlashIcon from "./SignalSlashIcon.js";
import _SignalIcon from "./SignalIcon.js";
import _SlashIcon from "./SlashIcon.js";
import _SparklesIcon from "./SparklesIcon.js";
import _SpeakerWaveIcon from "./SpeakerWaveIcon.js";
import _SpeakerXMarkIcon from "./SpeakerXMarkIcon.js";
import _Square2StackIcon from "./Square2StackIcon.js";
import _Square3Stack3DIcon from "./Square3Stack3DIcon.js";
import _Squares2X2Icon from "./Squares2X2Icon.js";
import _SquaresPlusIcon from "./SquaresPlusIcon.js";
import _StarIcon from "./StarIcon.js";
import _StopCircleIcon from "./StopCircleIcon.js";
import _StopIcon from "./StopIcon.js";
import _StrikethroughIcon from "./StrikethroughIcon.js";
import _SunIcon from "./SunIcon.js";
import _SwatchIcon from "./SwatchIcon.js";
import _TableCellsIcon from "./TableCellsIcon.js";
import _TagIcon from "./TagIcon.js";
import _TicketIcon from "./TicketIcon.js";
import _TrashIcon from "./TrashIcon.js";
import _TrophyIcon from "./TrophyIcon.js";
import _TruckIcon from "./TruckIcon.js";
import _TvIcon from "./TvIcon.js";
import _UnderlineIcon from "./UnderlineIcon.js";
import _UserCircleIcon from "./UserCircleIcon.js";
import _UserGroupIcon from "./UserGroupIcon.js";
import _UserMinusIcon from "./UserMinusIcon.js";
import _UserPlusIcon from "./UserPlusIcon.js";
import _UserIcon from "./UserIcon.js";
import _UsersIcon from "./UsersIcon.js";
import _VariableIcon from "./VariableIcon.js";
import _VideoCameraSlashIcon from "./VideoCameraSlashIcon.js";
import _VideoCameraIcon from "./VideoCameraIcon.js";
import _ViewColumnsIcon from "./ViewColumnsIcon.js";
import _ViewfinderCircleIcon from "./ViewfinderCircleIcon.js";
import _WalletIcon from "./WalletIcon.js";
import _WifiIcon from "./WifiIcon.js";
import _WindowIcon from "./WindowIcon.js";
import _WrenchScrewdriverIcon from "./WrenchScrewdriverIcon.js";
import _WrenchIcon from "./WrenchIcon.js";
import _XCircleIcon from "./XCircleIcon.js";
import _XMarkIcon from "./XMarkIcon.js";
var exports = {};
exports.AcademicCapIcon = _AcademicCapIcon;
exports.AdjustmentsHorizontalIcon = _AdjustmentsHorizontalIcon;
exports.AdjustmentsVerticalIcon = _AdjustmentsVerticalIcon;
exports.ArchiveBoxArrowDownIcon = _ArchiveBoxArrowDownIcon;
exports.ArchiveBoxXMarkIcon = _ArchiveBoxXMarkIcon;
exports.ArchiveBoxIcon = _ArchiveBoxIcon;
exports.ArrowDownCircleIcon = _ArrowDownCircleIcon;
exports.ArrowDownLeftIcon = _ArrowDownLeftIcon;
exports.ArrowDownOnSquareStackIcon = _ArrowDownOnSquareStackIcon;
exports.ArrowDownOnSquareIcon = _ArrowDownOnSquareIcon;
exports.ArrowDownRightIcon = _ArrowDownRightIcon;
exports.ArrowDownTrayIcon = _ArrowDownTrayIcon;
exports.ArrowDownIcon = _ArrowDownIcon;
exports.ArrowLeftCircleIcon = _ArrowLeftCircleIcon;
exports.ArrowLeftEndOnRectangleIcon = _ArrowLeftEndOnRectangleIcon;
exports.ArrowLeftOnRectangleIcon = _ArrowLeftOnRectangleIcon;
exports.ArrowLeftStartOnRectangleIcon = _ArrowLeftStartOnRectangleIcon;
exports.ArrowLeftIcon = _ArrowLeftIcon;
exports.ArrowLongDownIcon = _ArrowLongDownIcon;
exports.ArrowLongLeftIcon = _ArrowLongLeftIcon;
exports.ArrowLongRightIcon = _ArrowLongRightIcon;
exports.ArrowLongUpIcon = _ArrowLongUpIcon;
exports.ArrowPathRoundedSquareIcon = _ArrowPathRoundedSquareIcon;
exports.ArrowPathIcon = _ArrowPathIcon;
exports.ArrowRightCircleIcon = _ArrowRightCircleIcon;
exports.ArrowRightEndOnRectangleIcon = _ArrowRightEndOnRectangleIcon;
exports.ArrowRightOnRectangleIcon = _ArrowRightOnRectangleIcon;
exports.ArrowRightStartOnRectangleIcon = _ArrowRightStartOnRectangleIcon;
exports.ArrowRightIcon = _ArrowRightIcon;
exports.ArrowSmallDownIcon = _ArrowSmallDownIcon;
exports.ArrowSmallLeftIcon = _ArrowSmallLeftIcon;
exports.ArrowSmallRightIcon = _ArrowSmallRightIcon;
exports.ArrowSmallUpIcon = _ArrowSmallUpIcon;
exports.ArrowTopRightOnSquareIcon = _ArrowTopRightOnSquareIcon;
exports.ArrowTrendingDownIcon = _ArrowTrendingDownIcon;
exports.ArrowTrendingUpIcon = _ArrowTrendingUpIcon;
exports.ArrowTurnDownLeftIcon = _ArrowTurnDownLeftIcon;
exports.ArrowTurnDownRightIcon = _ArrowTurnDownRightIcon;
exports.ArrowTurnLeftDownIcon = _ArrowTurnLeftDownIcon;
exports.ArrowTurnLeftUpIcon = _ArrowTurnLeftUpIcon;
exports.ArrowTurnRightDownIcon = _ArrowTurnRightDownIcon;
exports.ArrowTurnRightUpIcon = _ArrowTurnRightUpIcon;
exports.ArrowTurnUpLeftIcon = _ArrowTurnUpLeftIcon;
exports.ArrowTurnUpRightIcon = _ArrowTurnUpRightIcon;
exports.ArrowUpCircleIcon = _ArrowUpCircleIcon;
exports.ArrowUpLeftIcon = _ArrowUpLeftIcon;
exports.ArrowUpOnSquareStackIcon = _ArrowUpOnSquareStackIcon;
exports.ArrowUpOnSquareIcon = _ArrowUpOnSquareIcon;
exports.ArrowUpRightIcon = _ArrowUpRightIcon;
exports.ArrowUpTrayIcon = _ArrowUpTrayIcon;
exports.ArrowUpIcon = _ArrowUpIcon;
exports.ArrowUturnDownIcon = _ArrowUturnDownIcon;
exports.ArrowUturnLeftIcon = _ArrowUturnLeftIcon;
exports.ArrowUturnRightIcon = _ArrowUturnRightIcon;
exports.ArrowUturnUpIcon = _ArrowUturnUpIcon;
exports.ArrowsPointingInIcon = _ArrowsPointingInIcon;
exports.ArrowsPointingOutIcon = _ArrowsPointingOutIcon;
exports.ArrowsRightLeftIcon = _ArrowsRightLeftIcon;
exports.ArrowsUpDownIcon = _ArrowsUpDownIcon;
exports.AtSymbolIcon = _AtSymbolIcon;
exports.BackspaceIcon = _BackspaceIcon;
exports.BackwardIcon = _BackwardIcon;
exports.BanknotesIcon = _BanknotesIcon;
exports.Bars2Icon = _Bars2Icon;
exports.Bars3BottomLeftIcon = _Bars3BottomLeftIcon;
exports.Bars3BottomRightIcon = _Bars3BottomRightIcon;
exports.Bars3CenterLeftIcon = _Bars3CenterLeftIcon;
exports.Bars3Icon = _Bars3Icon;
exports.Bars4Icon = _Bars4Icon;
exports.BarsArrowDownIcon = _BarsArrowDownIcon;
exports.BarsArrowUpIcon = _BarsArrowUpIcon;
exports.Battery0Icon = _Battery0Icon;
exports.Battery100Icon = _Battery100Icon;
exports.Battery50Icon = _Battery50Icon;
exports.BeakerIcon = _BeakerIcon;
exports.BellAlertIcon = _BellAlertIcon;
exports.BellSlashIcon = _BellSlashIcon;
exports.BellSnoozeIcon = _BellSnoozeIcon;
exports.BellIcon = _BellIcon;
exports.BoldIcon = _BoldIcon;
exports.BoltSlashIcon = _BoltSlashIcon;
exports.BoltIcon = _BoltIcon;
exports.BookOpenIcon = _BookOpenIcon;
exports.BookmarkSlashIcon = _BookmarkSlashIcon;
exports.BookmarkSquareIcon = _BookmarkSquareIcon;
exports.BookmarkIcon = _BookmarkIcon;
exports.BriefcaseIcon = _BriefcaseIcon;
exports.BugAntIcon = _BugAntIcon;
exports.BuildingLibraryIcon = _BuildingLibraryIcon;
exports.BuildingOffice2Icon = _BuildingOffice2Icon;
exports.BuildingOfficeIcon = _BuildingOfficeIcon;
exports.BuildingStorefrontIcon = _BuildingStorefrontIcon;
exports.CakeIcon = _CakeIcon;
exports.CalculatorIcon = _CalculatorIcon;
exports.CalendarDateRangeIcon = _CalendarDateRangeIcon;
exports.CalendarDaysIcon = _CalendarDaysIcon;
exports.CalendarIcon = _CalendarIcon;
exports.CameraIcon = _CameraIcon;
exports.ChartBarSquareIcon = _ChartBarSquareIcon;
exports.ChartBarIcon = _ChartBarIcon;
exports.ChartPieIcon = _ChartPieIcon;
exports.ChatBubbleBottomCenterTextIcon = _ChatBubbleBottomCenterTextIcon;
exports.ChatBubbleBottomCenterIcon = _ChatBubbleBottomCenterIcon;
exports.ChatBubbleLeftEllipsisIcon = _ChatBubbleLeftEllipsisIcon;
exports.ChatBubbleLeftRightIcon = _ChatBubbleLeftRightIcon;
exports.ChatBubbleLeftIcon = _ChatBubbleLeftIcon;
exports.ChatBubbleOvalLeftEllipsisIcon = _ChatBubbleOvalLeftEllipsisIcon;
exports.ChatBubbleOvalLeftIcon = _ChatBubbleOvalLeftIcon;
exports.CheckBadgeIcon = _CheckBadgeIcon;
exports.CheckCircleIcon = _CheckCircleIcon;
exports.CheckIcon = _CheckIcon;
exports.ChevronDoubleDownIcon = _ChevronDoubleDownIcon;
exports.ChevronDoubleLeftIcon = _ChevronDoubleLeftIcon;
exports.ChevronDoubleRightIcon = _ChevronDoubleRightIcon;
exports.ChevronDoubleUpIcon = _ChevronDoubleUpIcon;
exports.ChevronDownIcon = _ChevronDownIcon;
exports.ChevronLeftIcon = _ChevronLeftIcon;
exports.ChevronRightIcon = _ChevronRightIcon;
exports.ChevronUpDownIcon = _ChevronUpDownIcon;
exports.ChevronUpIcon = _ChevronUpIcon;
exports.CircleStackIcon = _CircleStackIcon;
exports.ClipboardDocumentCheckIcon = _ClipboardDocumentCheckIcon;
exports.ClipboardDocumentListIcon = _ClipboardDocumentListIcon;
exports.ClipboardDocumentIcon = _ClipboardDocumentIcon;
exports.ClipboardIcon = _ClipboardIcon;
exports.ClockIcon = _ClockIcon;
exports.CloudArrowDownIcon = _CloudArrowDownIcon;
exports.CloudArrowUpIcon = _CloudArrowUpIcon;
exports.CloudIcon = _CloudIcon;
exports.CodeBracketSquareIcon = _CodeBracketSquareIcon;
exports.CodeBracketIcon = _CodeBracketIcon;
exports.Cog6ToothIcon = _Cog6ToothIcon;
exports.Cog8ToothIcon = _Cog8ToothIcon;
exports.CogIcon = _CogIcon;
exports.CommandLineIcon = _CommandLineIcon;
exports.ComputerDesktopIcon = _ComputerDesktopIcon;
exports.CpuChipIcon = _CpuChipIcon;
exports.CreditCardIcon = _CreditCardIcon;
exports.CubeTransparentIcon = _CubeTransparentIcon;
exports.CubeIcon = _CubeIcon;
exports.CurrencyBangladeshiIcon = _CurrencyBangladeshiIcon;
exports.CurrencyDollarIcon = _CurrencyDollarIcon;
exports.CurrencyEuroIcon = _CurrencyEuroIcon;
exports.CurrencyPoundIcon = _CurrencyPoundIcon;
exports.CurrencyRupeeIcon = _CurrencyRupeeIcon;
exports.CurrencyYenIcon = _CurrencyYenIcon;
exports.CursorArrowRaysIcon = _CursorArrowRaysIcon;
exports.CursorArrowRippleIcon = _CursorArrowRippleIcon;
exports.DevicePhoneMobileIcon = _DevicePhoneMobileIcon;
exports.DeviceTabletIcon = _DeviceTabletIcon;
exports.DivideIcon = _DivideIcon;
exports.DocumentArrowDownIcon = _DocumentArrowDownIcon;
exports.DocumentArrowUpIcon = _DocumentArrowUpIcon;
exports.DocumentChartBarIcon = _DocumentChartBarIcon;
exports.DocumentCheckIcon = _DocumentCheckIcon;
exports.DocumentCurrencyBangladeshiIcon = _DocumentCurrencyBangladeshiIcon;
exports.DocumentCurrencyDollarIcon = _DocumentCurrencyDollarIcon;
exports.DocumentCurrencyEuroIcon = _DocumentCurrencyEuroIcon;
exports.DocumentCurrencyPoundIcon = _DocumentCurrencyPoundIcon;
exports.DocumentCurrencyRupeeIcon = _DocumentCurrencyRupeeIcon;
exports.DocumentCurrencyYenIcon = _DocumentCurrencyYenIcon;
exports.DocumentDuplicateIcon = _DocumentDuplicateIcon;
exports.DocumentMagnifyingGlassIcon = _DocumentMagnifyingGlassIcon;
exports.DocumentMinusIcon = _DocumentMinusIcon;
exports.DocumentPlusIcon = _DocumentPlusIcon;
exports.DocumentTextIcon = _DocumentTextIcon;
exports.DocumentIcon = _DocumentIcon;
exports.EllipsisHorizontalCircleIcon = _EllipsisHorizontalCircleIcon;
exports.EllipsisHorizontalIcon = _EllipsisHorizontalIcon;
exports.EllipsisVerticalIcon = _EllipsisVerticalIcon;
exports.EnvelopeOpenIcon = _EnvelopeOpenIcon;
exports.EnvelopeIcon = _EnvelopeIcon;
exports.EqualsIcon = _EqualsIcon;
exports.ExclamationCircleIcon = _ExclamationCircleIcon;
exports.ExclamationTriangleIcon = _ExclamationTriangleIcon;
exports.EyeDropperIcon = _EyeDropperIcon;
exports.EyeSlashIcon = _EyeSlashIcon;
exports.EyeIcon = _EyeIcon;
exports.FaceFrownIcon = _FaceFrownIcon;
exports.FaceSmileIcon = _FaceSmileIcon;
exports.FilmIcon = _FilmIcon;
exports.FingerPrintIcon = _FingerPrintIcon;
exports.FireIcon = _FireIcon;
exports.FlagIcon = _FlagIcon;
exports.FolderArrowDownIcon = _FolderArrowDownIcon;
exports.FolderMinusIcon = _FolderMinusIcon;
exports.FolderOpenIcon = _FolderOpenIcon;
exports.FolderPlusIcon = _FolderPlusIcon;
exports.FolderIcon = _FolderIcon;
exports.ForwardIcon = _ForwardIcon;
exports.FunnelIcon = _FunnelIcon;
exports.GifIcon = _GifIcon;
exports.GiftTopIcon = _GiftTopIcon;
exports.GiftIcon = _GiftIcon;
exports.GlobeAltIcon = _GlobeAltIcon;
exports.GlobeAmericasIcon = _GlobeAmericasIcon;
exports.GlobeAsiaAustraliaIcon = _GlobeAsiaAustraliaIcon;
exports.GlobeEuropeAfricaIcon = _GlobeEuropeAfricaIcon;
exports.H1Icon = _H1Icon;
exports.H2Icon = _H2Icon;
exports.H3Icon = _H3Icon;
exports.HandRaisedIcon = _HandRaisedIcon;
exports.HandThumbDownIcon = _HandThumbDownIcon;
exports.HandThumbUpIcon = _HandThumbUpIcon;
exports.HashtagIcon = _HashtagIcon;
exports.HeartIcon = _HeartIcon;
exports.HomeModernIcon = _HomeModernIcon;
exports.HomeIcon = _HomeIcon;
exports.IdentificationIcon = _IdentificationIcon;
exports.InboxArrowDownIcon = _InboxArrowDownIcon;
exports.InboxStackIcon = _InboxStackIcon;
exports.InboxIcon = _InboxIcon;
exports.InformationCircleIcon = _InformationCircleIcon;
exports.ItalicIcon = _ItalicIcon;
exports.KeyIcon = _KeyIcon;
exports.LanguageIcon = _LanguageIcon;
exports.LifebuoyIcon = _LifebuoyIcon;
exports.LightBulbIcon = _LightBulbIcon;
exports.LinkSlashIcon = _LinkSlashIcon;
exports.LinkIcon = _LinkIcon;
exports.ListBulletIcon = _ListBulletIcon;
exports.LockClosedIcon = _LockClosedIcon;
exports.LockOpenIcon = _LockOpenIcon;
exports.MagnifyingGlassCircleIcon = _MagnifyingGlassCircleIcon;
exports.MagnifyingGlassMinusIcon = _MagnifyingGlassMinusIcon;
exports.MagnifyingGlassPlusIcon = _MagnifyingGlassPlusIcon;
exports.MagnifyingGlassIcon = _MagnifyingGlassIcon;
exports.MapPinIcon = _MapPinIcon;
exports.MapIcon = _MapIcon;
exports.MegaphoneIcon = _MegaphoneIcon;
exports.MicrophoneIcon = _MicrophoneIcon;
exports.MinusCircleIcon = _MinusCircleIcon;
exports.MinusSmallIcon = _MinusSmallIcon;
exports.MinusIcon = _MinusIcon;
exports.MoonIcon = _MoonIcon;
exports.MusicalNoteIcon = _MusicalNoteIcon;
exports.NewspaperIcon = _NewspaperIcon;
exports.NoSymbolIcon = _NoSymbolIcon;
exports.NumberedListIcon = _NumberedListIcon;
exports.PaintBrushIcon = _PaintBrushIcon;
exports.PaperAirplaneIcon = _PaperAirplaneIcon;
exports.PaperClipIcon = _PaperClipIcon;
exports.PauseCircleIcon = _PauseCircleIcon;
exports.PauseIcon = _PauseIcon;
exports.PencilSquareIcon = _PencilSquareIcon;
exports.PencilIcon = _PencilIcon;
exports.PercentBadgeIcon = _PercentBadgeIcon;
exports.PhoneArrowDownLeftIcon = _PhoneArrowDownLeftIcon;
exports.PhoneArrowUpRightIcon = _PhoneArrowUpRightIcon;
exports.PhoneXMarkIcon = _PhoneXMarkIcon;
exports.PhoneIcon = _PhoneIcon;
exports.PhotoIcon = _PhotoIcon;
exports.PlayCircleIcon = _PlayCircleIcon;
exports.PlayPauseIcon = _PlayPauseIcon;
exports.PlayIcon = _PlayIcon;
exports.PlusCircleIcon = _PlusCircleIcon;
exports.PlusSmallIcon = _PlusSmallIcon;
exports.PlusIcon = _PlusIcon;
exports.PowerIcon = _PowerIcon;
exports.PresentationChartBarIcon = _PresentationChartBarIcon;
exports.PresentationChartLineIcon = _PresentationChartLineIcon;
exports.PrinterIcon = _PrinterIcon;
exports.PuzzlePieceIcon = _PuzzlePieceIcon;
exports.QrCodeIcon = _QrCodeIcon;
exports.QuestionMarkCircleIcon = _QuestionMarkCircleIcon;
exports.QueueListIcon = _QueueListIcon;
exports.RadioIcon = _RadioIcon;
exports.ReceiptPercentIcon = _ReceiptPercentIcon;
exports.ReceiptRefundIcon = _ReceiptRefundIcon;
exports.RectangleGroupIcon = _RectangleGroupIcon;
exports.RectangleStackIcon = _RectangleStackIcon;
exports.RocketLaunchIcon = _RocketLaunchIcon;
exports.RssIcon = _RssIcon;
exports.ScaleIcon = _ScaleIcon;
exports.ScissorsIcon = _ScissorsIcon;
exports.ServerStackIcon = _ServerStackIcon;
exports.ServerIcon = _ServerIcon;
exports.ShareIcon = _ShareIcon;
exports.ShieldCheckIcon = _ShieldCheckIcon;
exports.ShieldExclamationIcon = _ShieldExclamationIcon;
exports.ShoppingBagIcon = _ShoppingBagIcon;
exports.ShoppingCartIcon = _ShoppingCartIcon;
exports.SignalSlashIcon = _SignalSlashIcon;
exports.SignalIcon = _SignalIcon;
exports.SlashIcon = _SlashIcon;
exports.SparklesIcon = _SparklesIcon;
exports.SpeakerWaveIcon = _SpeakerWaveIcon;
exports.SpeakerXMarkIcon = _SpeakerXMarkIcon;
exports.Square2StackIcon = _Square2StackIcon;
exports.Square3Stack3DIcon = _Square3Stack3DIcon;
exports.Squares2X2Icon = _Squares2X2Icon;
exports.SquaresPlusIcon = _SquaresPlusIcon;
exports.StarIcon = _StarIcon;
exports.StopCircleIcon = _StopCircleIcon;
exports.StopIcon = _StopIcon;
exports.StrikethroughIcon = _StrikethroughIcon;
exports.SunIcon = _SunIcon;
exports.SwatchIcon = _SwatchIcon;
exports.TableCellsIcon = _TableCellsIcon;
exports.TagIcon = _TagIcon;
exports.TicketIcon = _TicketIcon;
exports.TrashIcon = _TrashIcon;
exports.TrophyIcon = _TrophyIcon;
exports.TruckIcon = _TruckIcon;
exports.TvIcon = _TvIcon;
exports.UnderlineIcon = _UnderlineIcon;
exports.UserCircleIcon = _UserCircleIcon;
exports.UserGroupIcon = _UserGroupIcon;
exports.UserMinusIcon = _UserMinusIcon;
exports.UserPlusIcon = _UserPlusIcon;
exports.UserIcon = _UserIcon;
exports.UsersIcon = _UsersIcon;
exports.VariableIcon = _VariableIcon;
exports.VideoCameraSlashIcon = _VideoCameraSlashIcon;
exports.VideoCameraIcon = _VideoCameraIcon;
exports.ViewColumnsIcon = _ViewColumnsIcon;
exports.ViewfinderCircleIcon = _ViewfinderCircleIcon;
exports.WalletIcon = _WalletIcon;
exports.WifiIcon = _WifiIcon;
exports.WindowIcon = _WindowIcon;
exports.WrenchScrewdriverIcon = _WrenchScrewdriverIcon;
exports.WrenchIcon = _WrenchIcon;
exports.XCircleIcon = _XCircleIcon;
exports.XMarkIcon = _XMarkIcon;
export default exports;
export const AcademicCapIcon = exports.AcademicCapIcon,
  AdjustmentsHorizontalIcon = exports.AdjustmentsHorizontalIcon,
  AdjustmentsVerticalIcon = exports.AdjustmentsVerticalIcon,
  ArchiveBoxArrowDownIcon = exports.ArchiveBoxArrowDownIcon,
  ArchiveBoxXMarkIcon = exports.ArchiveBoxXMarkIcon,
  ArchiveBoxIcon = exports.ArchiveBoxIcon,
  ArrowDownCircleIcon = exports.ArrowDownCircleIcon,
  ArrowDownLeftIcon = exports.ArrowDownLeftIcon,
  ArrowDownOnSquareStackIcon = exports.ArrowDownOnSquareStackIcon,
  ArrowDownOnSquareIcon = exports.ArrowDownOnSquareIcon,
  ArrowDownRightIcon = exports.ArrowDownRightIcon,
  ArrowDownTrayIcon = exports.ArrowDownTrayIcon,
  ArrowDownIcon = exports.ArrowDownIcon,
  ArrowLeftCircleIcon = exports.ArrowLeftCircleIcon,
  ArrowLeftEndOnRectangleIcon = exports.ArrowLeftEndOnRectangleIcon,
  ArrowLeftOnRectangleIcon = exports.ArrowLeftOnRectangleIcon,
  ArrowLeftStartOnRectangleIcon = exports.ArrowLeftStartOnRectangleIcon,
  ArrowLeftIcon = exports.ArrowLeftIcon,
  ArrowLongDownIcon = exports.ArrowLongDownIcon,
  ArrowLongLeftIcon = exports.ArrowLongLeftIcon,
  ArrowLongRightIcon = exports.ArrowLongRightIcon,
  ArrowLongUpIcon = exports.ArrowLongUpIcon,
  ArrowPathRoundedSquareIcon = exports.ArrowPathRoundedSquareIcon,
  ArrowPathIcon = exports.ArrowPathIcon,
  ArrowRightCircleIcon = exports.ArrowRightCircleIcon,
  ArrowRightEndOnRectangleIcon = exports.ArrowRightEndOnRectangleIcon,
  ArrowRightOnRectangleIcon = exports.ArrowRightOnRectangleIcon,
  ArrowRightStartOnRectangleIcon = exports.ArrowRightStartOnRectangleIcon,
  ArrowRightIcon = exports.ArrowRightIcon,
  ArrowSmallDownIcon = exports.ArrowSmallDownIcon,
  ArrowSmallLeftIcon = exports.ArrowSmallLeftIcon,
  ArrowSmallRightIcon = exports.ArrowSmallRightIcon,
  ArrowSmallUpIcon = exports.ArrowSmallUpIcon,
  ArrowTopRightOnSquareIcon = exports.ArrowTopRightOnSquareIcon,
  ArrowTrendingDownIcon = exports.ArrowTrendingDownIcon,
  ArrowTrendingUpIcon = exports.ArrowTrendingUpIcon,
  ArrowTurnDownLeftIcon = exports.ArrowTurnDownLeftIcon,
  ArrowTurnDownRightIcon = exports.ArrowTurnDownRightIcon,
  ArrowTurnLeftDownIcon = exports.ArrowTurnLeftDownIcon,
  ArrowTurnLeftUpIcon = exports.ArrowTurnLeftUpIcon,
  ArrowTurnRightDownIcon = exports.ArrowTurnRightDownIcon,
  ArrowTurnRightUpIcon = exports.ArrowTurnRightUpIcon,
  ArrowTurnUpLeftIcon = exports.ArrowTurnUpLeftIcon,
  ArrowTurnUpRightIcon = exports.ArrowTurnUpRightIcon,
  ArrowUpCircleIcon = exports.ArrowUpCircleIcon,
  ArrowUpLeftIcon = exports.ArrowUpLeftIcon,
  ArrowUpOnSquareStackIcon = exports.ArrowUpOnSquareStackIcon,
  ArrowUpOnSquareIcon = exports.ArrowUpOnSquareIcon,
  ArrowUpRightIcon = exports.ArrowUpRightIcon,
  ArrowUpTrayIcon = exports.ArrowUpTrayIcon,
  ArrowUpIcon = exports.ArrowUpIcon,
  ArrowUturnDownIcon = exports.ArrowUturnDownIcon,
  ArrowUturnLeftIcon = exports.ArrowUturnLeftIcon,
  ArrowUturnRightIcon = exports.ArrowUturnRightIcon,
  ArrowUturnUpIcon = exports.ArrowUturnUpIcon,
  ArrowsPointingInIcon = exports.ArrowsPointingInIcon,
  ArrowsPointingOutIcon = exports.ArrowsPointingOutIcon,
  ArrowsRightLeftIcon = exports.ArrowsRightLeftIcon,
  ArrowsUpDownIcon = exports.ArrowsUpDownIcon,
  AtSymbolIcon = exports.AtSymbolIcon,
  BackspaceIcon = exports.BackspaceIcon,
  BackwardIcon = exports.BackwardIcon,
  BanknotesIcon = exports.BanknotesIcon,
  Bars2Icon = exports.Bars2Icon,
  Bars3BottomLeftIcon = exports.Bars3BottomLeftIcon,
  Bars3BottomRightIcon = exports.Bars3BottomRightIcon,
  Bars3CenterLeftIcon = exports.Bars3CenterLeftIcon,
  Bars3Icon = exports.Bars3Icon,
  Bars4Icon = exports.Bars4Icon,
  BarsArrowDownIcon = exports.BarsArrowDownIcon,
  BarsArrowUpIcon = exports.BarsArrowUpIcon,
  Battery0Icon = exports.Battery0Icon,
  Battery100Icon = exports.Battery100Icon,
  Battery50Icon = exports.Battery50Icon,
  BeakerIcon = exports.BeakerIcon,
  BellAlertIcon = exports.BellAlertIcon,
  BellSlashIcon = exports.BellSlashIcon,
  BellSnoozeIcon = exports.BellSnoozeIcon,
  BellIcon = exports.BellIcon,
  BoldIcon = exports.BoldIcon,
  BoltSlashIcon = exports.BoltSlashIcon,
  BoltIcon = exports.BoltIcon,
  BookOpenIcon = exports.BookOpenIcon,
  BookmarkSlashIcon = exports.BookmarkSlashIcon,
  BookmarkSquareIcon = exports.BookmarkSquareIcon,
  BookmarkIcon = exports.BookmarkIcon,
  BriefcaseIcon = exports.BriefcaseIcon,
  BugAntIcon = exports.BugAntIcon,
  BuildingLibraryIcon = exports.BuildingLibraryIcon,
  BuildingOffice2Icon = exports.BuildingOffice2Icon,
  BuildingOfficeIcon = exports.BuildingOfficeIcon,
  BuildingStorefrontIcon = exports.BuildingStorefrontIcon,
  CakeIcon = exports.CakeIcon,
  CalculatorIcon = exports.CalculatorIcon,
  CalendarDateRangeIcon = exports.CalendarDateRangeIcon,
  CalendarDaysIcon = exports.CalendarDaysIcon,
  CalendarIcon = exports.CalendarIcon,
  CameraIcon = exports.CameraIcon,
  ChartBarSquareIcon = exports.ChartBarSquareIcon,
  ChartBarIcon = exports.ChartBarIcon,
  ChartPieIcon = exports.ChartPieIcon,
  ChatBubbleBottomCenterTextIcon = exports.ChatBubbleBottomCenterTextIcon,
  ChatBubbleBottomCenterIcon = exports.ChatBubbleBottomCenterIcon,
  ChatBubbleLeftEllipsisIcon = exports.ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon = exports.ChatBubbleLeftRightIcon,
  ChatBubbleLeftIcon = exports.ChatBubbleLeftIcon,
  ChatBubbleOvalLeftEllipsisIcon = exports.ChatBubbleOvalLeftEllipsisIcon,
  ChatBubbleOvalLeftIcon = exports.ChatBubbleOvalLeftIcon,
  CheckBadgeIcon = exports.CheckBadgeIcon,
  CheckCircleIcon = exports.CheckCircleIcon,
  CheckIcon = exports.CheckIcon,
  ChevronDoubleDownIcon = exports.ChevronDoubleDownIcon,
  ChevronDoubleLeftIcon = exports.ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon = exports.ChevronDoubleRightIcon,
  ChevronDoubleUpIcon = exports.ChevronDoubleUpIcon,
  ChevronDownIcon = exports.ChevronDownIcon,
  ChevronLeftIcon = exports.ChevronLeftIcon,
  ChevronRightIcon = exports.ChevronRightIcon,
  ChevronUpDownIcon = exports.ChevronUpDownIcon,
  ChevronUpIcon = exports.ChevronUpIcon,
  CircleStackIcon = exports.CircleStackIcon,
  ClipboardDocumentCheckIcon = exports.ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon = exports.ClipboardDocumentListIcon,
  ClipboardDocumentIcon = exports.ClipboardDocumentIcon,
  ClipboardIcon = exports.ClipboardIcon,
  ClockIcon = exports.ClockIcon,
  CloudArrowDownIcon = exports.CloudArrowDownIcon,
  CloudArrowUpIcon = exports.CloudArrowUpIcon,
  CloudIcon = exports.CloudIcon,
  CodeBracketSquareIcon = exports.CodeBracketSquareIcon,
  CodeBracketIcon = exports.CodeBracketIcon,
  Cog6ToothIcon = exports.Cog6ToothIcon,
  Cog8ToothIcon = exports.Cog8ToothIcon,
  CogIcon = exports.CogIcon,
  CommandLineIcon = exports.CommandLineIcon,
  ComputerDesktopIcon = exports.ComputerDesktopIcon,
  CpuChipIcon = exports.CpuChipIcon,
  CreditCardIcon = exports.CreditCardIcon,
  CubeTransparentIcon = exports.CubeTransparentIcon,
  CubeIcon = exports.CubeIcon,
  CurrencyBangladeshiIcon = exports.CurrencyBangladeshiIcon,
  CurrencyDollarIcon = exports.CurrencyDollarIcon,
  CurrencyEuroIcon = exports.CurrencyEuroIcon,
  CurrencyPoundIcon = exports.CurrencyPoundIcon,
  CurrencyRupeeIcon = exports.CurrencyRupeeIcon,
  CurrencyYenIcon = exports.CurrencyYenIcon,
  CursorArrowRaysIcon = exports.CursorArrowRaysIcon,
  CursorArrowRippleIcon = exports.CursorArrowRippleIcon,
  DevicePhoneMobileIcon = exports.DevicePhoneMobileIcon,
  DeviceTabletIcon = exports.DeviceTabletIcon,
  DivideIcon = exports.DivideIcon,
  DocumentArrowDownIcon = exports.DocumentArrowDownIcon,
  DocumentArrowUpIcon = exports.DocumentArrowUpIcon,
  DocumentChartBarIcon = exports.DocumentChartBarIcon,
  DocumentCheckIcon = exports.DocumentCheckIcon,
  DocumentCurrencyBangladeshiIcon = exports.DocumentCurrencyBangladeshiIcon,
  DocumentCurrencyDollarIcon = exports.DocumentCurrencyDollarIcon,
  DocumentCurrencyEuroIcon = exports.DocumentCurrencyEuroIcon,
  DocumentCurrencyPoundIcon = exports.DocumentCurrencyPoundIcon,
  DocumentCurrencyRupeeIcon = exports.DocumentCurrencyRupeeIcon,
  DocumentCurrencyYenIcon = exports.DocumentCurrencyYenIcon,
  DocumentDuplicateIcon = exports.DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon = exports.DocumentMagnifyingGlassIcon,
  DocumentMinusIcon = exports.DocumentMinusIcon,
  DocumentPlusIcon = exports.DocumentPlusIcon,
  DocumentTextIcon = exports.DocumentTextIcon,
  DocumentIcon = exports.DocumentIcon,
  EllipsisHorizontalCircleIcon = exports.EllipsisHorizontalCircleIcon,
  EllipsisHorizontalIcon = exports.EllipsisHorizontalIcon,
  EllipsisVerticalIcon = exports.EllipsisVerticalIcon,
  EnvelopeOpenIcon = exports.EnvelopeOpenIcon,
  EnvelopeIcon = exports.EnvelopeIcon,
  EqualsIcon = exports.EqualsIcon,
  ExclamationCircleIcon = exports.ExclamationCircleIcon,
  ExclamationTriangleIcon = exports.ExclamationTriangleIcon,
  EyeDropperIcon = exports.EyeDropperIcon,
  EyeSlashIcon = exports.EyeSlashIcon,
  EyeIcon = exports.EyeIcon,
  FaceFrownIcon = exports.FaceFrownIcon,
  FaceSmileIcon = exports.FaceSmileIcon,
  FilmIcon = exports.FilmIcon,
  FingerPrintIcon = exports.FingerPrintIcon,
  FireIcon = exports.FireIcon,
  FlagIcon = exports.FlagIcon,
  FolderArrowDownIcon = exports.FolderArrowDownIcon,
  FolderMinusIcon = exports.FolderMinusIcon,
  FolderOpenIcon = exports.FolderOpenIcon,
  FolderPlusIcon = exports.FolderPlusIcon,
  FolderIcon = exports.FolderIcon,
  ForwardIcon = exports.ForwardIcon,
  FunnelIcon = exports.FunnelIcon,
  GifIcon = exports.GifIcon,
  GiftTopIcon = exports.GiftTopIcon,
  GiftIcon = exports.GiftIcon,
  GlobeAltIcon = exports.GlobeAltIcon,
  GlobeAmericasIcon = exports.GlobeAmericasIcon,
  GlobeAsiaAustraliaIcon = exports.GlobeAsiaAustraliaIcon,
  GlobeEuropeAfricaIcon = exports.GlobeEuropeAfricaIcon,
  H1Icon = exports.H1Icon,
  H2Icon = exports.H2Icon,
  H3Icon = exports.H3Icon,
  HandRaisedIcon = exports.HandRaisedIcon,
  HandThumbDownIcon = exports.HandThumbDownIcon,
  HandThumbUpIcon = exports.HandThumbUpIcon,
  HashtagIcon = exports.HashtagIcon,
  HeartIcon = exports.HeartIcon,
  HomeModernIcon = exports.HomeModernIcon,
  HomeIcon = exports.HomeIcon,
  IdentificationIcon = exports.IdentificationIcon,
  InboxArrowDownIcon = exports.InboxArrowDownIcon,
  InboxStackIcon = exports.InboxStackIcon,
  InboxIcon = exports.InboxIcon,
  InformationCircleIcon = exports.InformationCircleIcon,
  ItalicIcon = exports.ItalicIcon,
  KeyIcon = exports.KeyIcon,
  LanguageIcon = exports.LanguageIcon,
  LifebuoyIcon = exports.LifebuoyIcon,
  LightBulbIcon = exports.LightBulbIcon,
  LinkSlashIcon = exports.LinkSlashIcon,
  LinkIcon = exports.LinkIcon,
  ListBulletIcon = exports.ListBulletIcon,
  LockClosedIcon = exports.LockClosedIcon,
  LockOpenIcon = exports.LockOpenIcon,
  MagnifyingGlassCircleIcon = exports.MagnifyingGlassCircleIcon,
  MagnifyingGlassMinusIcon = exports.MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon = exports.MagnifyingGlassPlusIcon,
  MagnifyingGlassIcon = exports.MagnifyingGlassIcon,
  MapPinIcon = exports.MapPinIcon,
  MapIcon = exports.MapIcon,
  MegaphoneIcon = exports.MegaphoneIcon,
  MicrophoneIcon = exports.MicrophoneIcon,
  MinusCircleIcon = exports.MinusCircleIcon,
  MinusSmallIcon = exports.MinusSmallIcon,
  MinusIcon = exports.MinusIcon,
  MoonIcon = exports.MoonIcon,
  MusicalNoteIcon = exports.MusicalNoteIcon,
  NewspaperIcon = exports.NewspaperIcon,
  NoSymbolIcon = exports.NoSymbolIcon,
  NumberedListIcon = exports.NumberedListIcon,
  PaintBrushIcon = exports.PaintBrushIcon,
  PaperAirplaneIcon = exports.PaperAirplaneIcon,
  PaperClipIcon = exports.PaperClipIcon,
  PauseCircleIcon = exports.PauseCircleIcon,
  PauseIcon = exports.PauseIcon,
  PencilSquareIcon = exports.PencilSquareIcon,
  PencilIcon = exports.PencilIcon,
  PercentBadgeIcon = exports.PercentBadgeIcon,
  PhoneArrowDownLeftIcon = exports.PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon = exports.PhoneArrowUpRightIcon,
  PhoneXMarkIcon = exports.PhoneXMarkIcon,
  PhoneIcon = exports.PhoneIcon,
  PhotoIcon = exports.PhotoIcon,
  PlayCircleIcon = exports.PlayCircleIcon,
  PlayPauseIcon = exports.PlayPauseIcon,
  PlayIcon = exports.PlayIcon,
  PlusCircleIcon = exports.PlusCircleIcon,
  PlusSmallIcon = exports.PlusSmallIcon,
  PlusIcon = exports.PlusIcon,
  PowerIcon = exports.PowerIcon,
  PresentationChartBarIcon = exports.PresentationChartBarIcon,
  PresentationChartLineIcon = exports.PresentationChartLineIcon,
  PrinterIcon = exports.PrinterIcon,
  PuzzlePieceIcon = exports.PuzzlePieceIcon,
  QrCodeIcon = exports.QrCodeIcon,
  QuestionMarkCircleIcon = exports.QuestionMarkCircleIcon,
  QueueListIcon = exports.QueueListIcon,
  RadioIcon = exports.RadioIcon,
  ReceiptPercentIcon = exports.ReceiptPercentIcon,
  ReceiptRefundIcon = exports.ReceiptRefundIcon,
  RectangleGroupIcon = exports.RectangleGroupIcon,
  RectangleStackIcon = exports.RectangleStackIcon,
  RocketLaunchIcon = exports.RocketLaunchIcon,
  RssIcon = exports.RssIcon,
  ScaleIcon = exports.ScaleIcon,
  ScissorsIcon = exports.ScissorsIcon,
  ServerStackIcon = exports.ServerStackIcon,
  ServerIcon = exports.ServerIcon,
  ShareIcon = exports.ShareIcon,
  ShieldCheckIcon = exports.ShieldCheckIcon,
  ShieldExclamationIcon = exports.ShieldExclamationIcon,
  ShoppingBagIcon = exports.ShoppingBagIcon,
  ShoppingCartIcon = exports.ShoppingCartIcon,
  SignalSlashIcon = exports.SignalSlashIcon,
  SignalIcon = exports.SignalIcon,
  SlashIcon = exports.SlashIcon,
  SparklesIcon = exports.SparklesIcon,
  SpeakerWaveIcon = exports.SpeakerWaveIcon,
  SpeakerXMarkIcon = exports.SpeakerXMarkIcon,
  Square2StackIcon = exports.Square2StackIcon,
  Square3Stack3DIcon = exports.Square3Stack3DIcon,
  Squares2X2Icon = exports.Squares2X2Icon,
  SquaresPlusIcon = exports.SquaresPlusIcon,
  StarIcon = exports.StarIcon,
  StopCircleIcon = exports.StopCircleIcon,
  StopIcon = exports.StopIcon,
  StrikethroughIcon = exports.StrikethroughIcon,
  SunIcon = exports.SunIcon,
  SwatchIcon = exports.SwatchIcon,
  TableCellsIcon = exports.TableCellsIcon,
  TagIcon = exports.TagIcon,
  TicketIcon = exports.TicketIcon,
  TrashIcon = exports.TrashIcon,
  TrophyIcon = exports.TrophyIcon,
  TruckIcon = exports.TruckIcon,
  TvIcon = exports.TvIcon,
  UnderlineIcon = exports.UnderlineIcon,
  UserCircleIcon = exports.UserCircleIcon,
  UserGroupIcon = exports.UserGroupIcon,
  UserMinusIcon = exports.UserMinusIcon,
  UserPlusIcon = exports.UserPlusIcon,
  UserIcon = exports.UserIcon,
  UsersIcon = exports.UsersIcon,
  VariableIcon = exports.VariableIcon,
  VideoCameraSlashIcon = exports.VideoCameraSlashIcon,
  VideoCameraIcon = exports.VideoCameraIcon,
  ViewColumnsIcon = exports.ViewColumnsIcon,
  ViewfinderCircleIcon = exports.ViewfinderCircleIcon,
  WalletIcon = exports.WalletIcon,
  WifiIcon = exports.WifiIcon,
  WindowIcon = exports.WindowIcon,
  WrenchScrewdriverIcon = exports.WrenchScrewdriverIcon,
  WrenchIcon = exports.WrenchIcon,
  XCircleIcon = exports.XCircleIcon,
  XMarkIcon = exports.XMarkIcon;